import React, { useCallback, useEffect, useState, Suspense } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { formDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  HStack,
  VStack,
  Text,
  Box,
  Image,
  useMediaQuery,
  CircularProgress,
} from "@chakra-ui/react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { userStorage } from "utils/helper";
import useNotificationToast from "utils/useToastNotification";
import inspeqtLogo from "../../../../assets/inspeqt-Logo-01.svg";
import {
  validateEmail,
  validateMobileNumber,
  validateText,
} from "utils/helper";

import HeaderGray from "views/Workflow/components/headerGray";
import { Button } from "@agnext/reactlib";
import Section from "./section";

// imports with lazy loading
// const HeaderGray = React.lazy(() => import("../../components/headerGray"));
// const Button = React.lazy(() =>
//   import("@agnext/reactlib").then((module) => ({ default: module.Button }))
// );
// const Section = React.lazy(() => import("./section"));


// Utility functions
import { convertToCamelCase, generateUUId } from "utils/utils";
import { postData } from "services/index";
// import { getBackendHealth } from "services/apis";
import { parseData } from "utils/helper";

export default function GenerateTemplate({ isFor }) {
  const { id } = useParams();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: formDataSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({});

  const [formErrorData, setFormErrorData] = useState({});

  const [tempError, setTempError] = useState(false);
  const [isValidFormat, setIsValidFormat] = useState(null);

  const [page, setPage] = useState(0);

  const [pageStatus, setPageStatus] = useState({});

  const [filledPages, setFilledPages] = useState(0);

  const [skippedPages, setSkippedPages] = useState({ from: 0, to: 0 });

  const [prevPages, setPrevPages] = useState([]);

  const [blurCount, setBlurCount] = useState(0);

  // const [isOnline, setIsOnline] = useState(navigator.online);

  const nToast = useNotificationToast();

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [smallerScreen] = useMediaQuery("(max-width: 1300px)");

  const form = useSelector(selectors.selectForm);

  const [displayData, setDisplayData] = useState([]);

  const [navIndex, setNavIndex] = useState(null);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );
  let pageInfoWeb = useSelector(selectors.selectLastUpdatedPages);

  const formLoading = useSelector(selectors.selectFormLoading);

  const selectFilesUploadLoader = useSelector(
    selectors.selectFilesUploadLoader
  ); //Used to disable next/submit button while upload process is in progress.

  const handleBlur = () => {
    setBlurCount((prevCount) => {
      const newCount = prevCount + 1;
      return newCount;
    });
  };

  const getElementsState = useCallback(
    (inspectionId) => {
      const elements = componentsForElements.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            title: currEl.label,
            name: convertToCamelCase(currEl.label),
            value: "",
            attachments: [],
            itemType: currEl.htmlType,
            mimeType: "NA",
            itemOrder: currEl.order,
            parentId: cv.id,
            parentType: "component",
            parentInfo: {
              id: cv.id,
              title: cv.title,
            },
            id: generateUUId(),
            formId: form[0].id,
            templateItemId: currEl.id,
            itemInfo: currEl.properties
              ? { properties: currEl.properties }
              : {},
            inspectionId: inspectionId,
            mandatory: currEl.mandatory,
            tableName: "inspectionItems",
          };
        });
        return acc;
      }, {});
      return elements;
    },
    [componentsForElements, form]
  );
  const getMandatoryStatusInElementState = useCallback(
    ({ newFormData, components }) => {
      const elements = components.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            ...newFormData[currEl.id],
            mandatory: currEl.mandatory,
          };
        });
        return acc;
      }, {});

      return elements;
    },
    []
  );

  const getElements = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        componentForElements.structure.elements.forEach(
                          (element) => {
                            elementDisplayData.push({
                              blockId: blockForComposites.id,
                              componentId: componentForElements.id,
                              element,
                            });
                          }
                        );
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getComponents = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        elementDisplayData.push({
                          blockId: blockForComposites.id,
                          component: componentForElements,
                        });
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getDisplayData = useCallback(
    (changePageStatus = false) => {
      let displayData = [];
      let newPageStatus = { ...pageStatus };

      if (sectionsForBlocks) {
        _.cloneDeep(sectionsForBlocks)
          .sort((a, b) => a.order - b.order)
          .forEach((section, index) => {
            // verify after merge conflict resolve
            const pageNumber = index + 1;
            displayData.push({
              pageNumber,
              sections: section?.structure?.blocks || [],
              pageId: section.id,
            });
            if (changePageStatus) {
              if (pageNumber === 1) {
                newPageStatus[pageNumber] = true;
              } else {
                newPageStatus[pageNumber] = false;
              }
            }
          });
      }
      return { displayData, newPageStatus };
    },
    [sectionsForBlocks]
  );

  useEffect(() => {
    if (pageInfoWeb && pageInfoWeb.page) {
      let newPage = pageInfoWeb.page;
      let newPrevPage = [...pageInfoWeb.prevPages];
      if (pageInfoWeb?.skippedPages) {
        if (pageInfoWeb?.skippedPages?.from === pageInfoWeb.page) {
          newPage = pageInfoWeb.skippedPages.to - 1;
          newPrevPage.push(pageInfoWeb.skippedPages.from);
        } else if (pageInfoWeb?.skippedPages?.to === pageInfoWeb.page) {
          newPrevPage.push(pageInfoWeb.skippedPages.to);
        } else {
          newPrevPage.push(newPage);
        }
      }
      setPage(newPage);

      setFilledPages(pageInfoWeb.page);
      if (pageInfoWeb.prevPages) {
        setPrevPages(newPrevPage);
      }
      if (pageInfoWeb.pageStatus) {
        setPageStatus(pageInfoWeb.pageStatus);
      }
    }
  }, [pageInfoWeb]);

  useEffect(() => {
    let newPageStatus = { ...pageStatus };
    newPageStatus[page + 1] = true;
    setPageStatus(newPageStatus);
  }, [page]);

  useEffect(() => {
    if (
      skippedPages.from &&
      skippedPages.to &&
      skippedPages.from < skippedPages.to - 1
    ) {
      let newPageStatus = { ...pageStatus };
      for (let i = skippedPages.from + 1; i < skippedPages.to; i++) {
        newPageStatus[i] = false;
      }
      setPageStatus(newPageStatus);
    }
  }, [skippedPages]);

  useEffect(() => {
    setDisplayData(getDisplayData().displayData);
    if (!pageInfoWeb && !pageInfoWeb?.page) {
      setPageStatus(getDisplayData(true).newPageStatus);
    }
  }, [getDisplayData]);

  useEffect(() => {
    if (isFor === "web") {
      setFormData(getElementsState());
    }
  }, [getElementsState]);

  const handleFormDataChange = (e, val, id, mimeType, order) => {
    setTempError(false);
    setIsValidFormat(null);

    // for repeatables delete
    if (val === "repeatable-delete") {
      setFormData((prevState) => {
        let attachments = prevState[e].attachments.filter(
          (_, index) => index !== id
        );
        return {
          ...prevState,
          [e]: {
            ...prevState[e],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatable dates
    if (val === "repeatableDate") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[mimeType] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }
    //for repeatable Dropdown & Radio
    if (val === "repeatableDD") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[mimeType - 1] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }
    // for repeatables
    if (val === "repeatable") {
      const { value, name } = e.target;
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[name].attachments);
        attachments[id - 1] = value;
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatable checkbox
    if (val === "repeatableCB") {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[order - 1] = e;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
          },
        };
      });
      return;
    }

    // for repeatable file upload
    if (_.isArray(val) && order) {
      setFormData((prevState) => {
        let attachments = _.cloneDeep(prevState[id].attachments);
        attachments[order - 1] = JSON.stringify(val);
        let itemInfo = _.cloneDeep(prevState[id]?.itemInfo);
        let newHashArray;
        if (itemInfo && itemInfo.hashArray) {
          newHashArray = itemInfo.hashArray;
          newHashArray[order - 1] = e;
        } else {
          newHashArray = [];
          newHashArray[order - 1] = e;
        }
        let newMimeTypes =
          prevState[id].mimeType && prevState[id].mimeType !== "NA"
            ? JSON.parse(prevState[id].mimeType)
            : [];
        newMimeTypes[order - 1] = mimeType;
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            attachments,
            itemInfo: {
              ...prevState[id]?.itemInfo,
              hashArray: newHashArray,
            },
            mimeType: JSON.stringify(newMimeTypes),
          },
        };
      });
      return;
    }

    if (_.isArray(val)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: [...val],
          itemInfo: {
            ...prevState[id]?.itemInfo,
            hashArray: e,
          },
          mimeType,
        },
      }));
      return;
    }
    if (_.isArray(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: e,
          mimeType,
        },
      }));
      return;
    }
    if (_.isString(e) || _.isDate(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: e,
        },
      }));
      return;
    }
    if (val && id) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: val,
        },
      }));
      return;
    }
    const { name, value, checked } = e.target;
    if (!val) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value || value === "" || value === 0 ? value : checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: val,
        },
      }));
    }
  };

  const getErrorState = useCallback(() => {
    let errorState = {};

    getDisplayData().displayData.forEach((elementData) => {
      const pageNumber = elementData.pageNumber;
      errorState[pageNumber] = {};
      const elements = [];
      elementData.sections.forEach((section) => {
        const newElements = getElements(section.id);
        elements.push(...newElements);
      });
      elements.forEach((element) => {
        errorState[pageNumber][element.element.id] = "";
      });
    });
    return errorState;
  }, [getDisplayData, getElements]);

  useEffect(() => {
    setFormErrorData(getErrorState());
  }, [getErrorState]);

  const handleValidate = (pageNumber) => {
    setIsValidFormat(null);
    let isValid = true;
    // for (let key of Object.keys(formErrorData[pageNumber]))
    Object.keys(formErrorData[pageNumber]).forEach((key) => {
      const currParentId = formData[key]?.parentId;
      const currComponent = componentsForElements?.find(
        (comp) => comp.id === currParentId
      );
      const isCurrComponentRepeatable = currComponent?.repeatable?.isRepeatable;
      const currElement = currComponent?.structure?.elements?.find(
        (element) => element.id === key
      );
      const isCurrElementRepeatable = currElement?.repeatable?.isRepeatable;

      if (currElement?.id === key) {
        if (isCurrComponentRepeatable || isCurrElementRepeatable) {
          const isEmpty = formData[key]?.attachments?.some((element) => {
            const ele = parseData(element);
            if (Array.isArray(ele)) {
              return ele.length === 0;
            } else {
              return ele === "";
            }
          });

          if (isEmpty && formData[key]?.mandatory) {
            isValid = false;
            let newFormErrorData = { ...formErrorData };
            newFormErrorData[pageNumber][key] = "This is a required field.";
            setFormErrorData(newFormErrorData);
            return isValid;
          }
        }
      }
      if (
        formData[key].mandatory &&
        !formData[key].value &&
        !formData[key]?.attachments?.length
      ) {
        isValid = false;
        let newFormErrorData = { ...formErrorData };
        newFormErrorData[pageNumber][key] = "This is a required field.";
        setFormErrorData(newFormErrorData);
        return isValid;
      }
      if (formData[key]?.itemType === "email") {
        const domainArr = formData[key]?.itemInfo?.properties?.emailDomains;
        const isEmailValidatedVal = validateEmail(
          formData[key]?.value,
          domainArr
        );
        let isEmailValidatedAttach = { isValid: true, message: "" };
        if (formData[key].attachments?.length > 0) {
          for (let val of formData[key]?.attachments) {
            isEmailValidatedAttach = validateEmail(val, domainArr);
            if (!isEmailValidatedAttach.isValid) {
              break;
            }
          }
        }

        if (!isEmailValidatedVal?.isValid || !isEmailValidatedAttach?.isValid) {
          isValid = false;
          let newFormErrorData = { ...formErrorData };
          newFormErrorData[pageNumber][key] =
            isEmailValidatedVal?.message || isEmailValidatedAttach?.message;
          setFormErrorData(newFormErrorData);
          setIsValidFormat("Please validate field format");
          return isValid;
        }
      }
      // for mobile numbers
      if (formData[key]?.itemType === "mobile") {
        // if mobile number attachement has data then this element is a RepeatableElement
        if (formData[key]?.attachments.length > 0) {
          for (let value of formData[key]?.attachments) {
            const valueArr = value.split(",");
            const number = valueArr?.length > 1 ? valueArr[1] : valueArr[0];
            const countryCode = valueArr?.length > 1 ? valueArr[0] : "+91";
            if (!number && formData[key].mandatory) {
              isValid = false;
              let newFormErrorData = { ...formErrorData };
              newFormErrorData[pageNumber][key] = "This is a required field.";
              setFormErrorData(newFormErrorData);
              return isValid;
            } else if (number) {
              const isValidMobileNumber = validateMobileNumber(
                number,
                countryCode
              );
              if (!isValidMobileNumber?.isValid) {
                isValid = false;
                let newFormErrorData = { ...formErrorData };
                newFormErrorData[pageNumber][key] =
                  isValidMobileNumber?.message;
                setFormErrorData(newFormErrorData);
                setIsValidFormat("Please validate field format");
              }
            }
          }
        } else {
          const valueArr = formData[key]?.value?.split(",");
          const number = valueArr?.length > 1 ? valueArr[1] : valueArr[0];
          const countryCode = valueArr?.length > 1 ? valueArr[0] : "+91";
          if (!number && formData[key].mandatory) {
            isValid = false;
            let newFormErrorData = { ...formErrorData };
            newFormErrorData[pageNumber][key] = "This is a required field.";
            setFormErrorData(newFormErrorData);
            return isValid;
          } else if (number) {
            const isValidMobileNumber = validateMobileNumber(
              number,
              countryCode
            );
            if (!isValidMobileNumber?.isValid) {
              isValid = false;
              let newFormErrorData = { ...formErrorData };
              newFormErrorData[pageNumber][key] = isValidMobileNumber?.message;
              setFormErrorData(newFormErrorData);
              setIsValidFormat("Please validate field format");
            }
          }
        }
      }

      // validation for the textInput and textarea fields textarea
      if (
        formData[key]?.itemType === "textInput" ||
        formData[key]?.itemType === "textarea"
      ) {
        const properties = formData[key]?.itemInfo?.properties;
        const isTextValidatedVal = validateText(
          formData[key]?.value,
          properties
        );
        let isTextValidatedAttach = { isValid: true, message: "" };
        if (formData[key].attachments?.length > 0) {
          for (let val of formData[key]?.attachments) {
            isTextValidatedAttach = validateText(val, properties);
            if (!isTextValidatedAttach?.isValid) {
              break;
            }
          }
        }

        if (!isTextValidatedVal?.isValid || !isTextValidatedAttach?.isValid) {
          isValid = false;
          let newFormErrorData = { ...formErrorData };
          newFormErrorData[pageNumber][key] =
            isTextValidatedVal?.message || isTextValidatedAttach?.message;
          setFormErrorData(newFormErrorData);
          setIsValidFormat("Please validate field format");
          return isValid;
        }
      }
      //end textInput and textarea validation
    });
    return isValid;
  };

  const createPreview = async ({ inspectionId, jobId, taskId }) => {
    dispatch(
      actions.createPreview({
        inspectionId,
        jobId,
        taskId,
        status: "preview",
      })
    );
  };

  const handleSubmitClick = async (e, status) => {
    // try {
    // const isOnline = await getBackendHealth();

    if (status === "inprogress") {
      // e.currentTarget.blur(); // Remove focus after click
      if (handleValidate(page + 1)) {
        setPage(navIndex ? navIndex : page + 1);

        // Ensure newPrevPages is always an array
        let newPrevPages = Array.isArray(prevPages) ? [...prevPages] : [];
        handleSubmit(status, page);
        newPrevPages.push(page + 1);
        setPrevPages(newPrevPages);
        setNavIndex(null);
      } else {
        setTempError(true);
      }
    } else if (status === "done") {
      // e.currentTarget.blur(); // Remove focus after click
      if (handleValidate(page + 1)) {
        handleSubmit(status);
      } else {
        setTempError(true);
      }
    }
    // } catch (err) {
    //   nToast({
    //     message: "No Internet Connection",
    //     status: "error",
    //   });
    // }
  };

  const handleSubmit = async (status, page, autoSave) => {
    setIsValidFormat(null);
    // if (isOnline === false) {
    //   nToast({
    //     message: "No Internet Connection",
    //     status: "error",
    //   });
    //   return "offline";
    // }
    if (!filledPages || filledPages <= page) {
      setFilledPages(page + 1);
    }

    let authToken = null,
      inspectionId,
      jobId,
      taskId;

    if (isFor !== "web") {
      const token = localStorage.getItem("token");
      const inspectionID = localStorage.getItem("inspectionId");
      const jobID = localStorage.getItem("jobId");
      const taskID = localStorage.getItem("taskId");
      authToken = JSON.parse(token);
      inspectionId = JSON.parse(inspectionID);
      jobId = JSON.parse(jobID);
      taskId = JSON.parse(taskID);
    }
    let newFormData = JSON.parse(JSON.stringify(formData));
    if (status === "done") {
      const { displayData } = getDisplayData();
      let i = 0;
      displayData.forEach((data) => {
        if (!pageStatus[data.pageNumber]) {
          data.sections.forEach((section) => {
            getElements(section.id).forEach((element) => {
              if (i == 0) {
                i++;
                newFormData = {
                  ...formData,
                  [element.element.id]: {
                    ...formData[element.element.id],
                    value: "",
                    attachments: [],
                  },
                };
              } else {
                newFormData = {
                  ...newFormData,
                  [element.element.id]: {
                    ...newFormData[element.element.id],
                    value: "",
                    attachments: [],
                  },
                };
              }
            });
          });
        }
      });
    }

    const data = [];
    Object.keys(newFormData).reduce((acc, cv) => {
      let item = { ...newFormData[cv] };

      if (item.itemType === "email") {
        if (item.value) {
          item.value = item.value.toLowerCase();
        } else if (item.attachments.some((val) => val)) {
          item.attachments = item.attachments.map((val) =>
            val ? val.toLowerCase() : ""
          );
        }
      }
      acc.push(item);
      return acc;
    }, data);

    let commonData = {
      status,
      data,
      pageInfo: null,
      inspectionId,
      jobId,
      taskId,
    };

    if (page || page === 0) {
      commonData.pageInfo = { page: page, pageStatus, prevPages, skippedPages };
    }

    let postRes;
    try {
      postRes =
        isFor === "web"
          ? await postData(commonData)
          : await postData({
              ...commonData,
              token: authToken,
            });

      if (postRes?.data?.success === true && status === "done") {
        //Do not remove this console log as it is being used as a flag for android app.
        createPreview({ inspectionId, jobId, taskId });
        isFor === "web"
          ? history.push("/admin/form-submitted")
          : console.log("Form Submitted Successfully");

        // setIsSubmit(false);
      }
    } catch (err) {
      // No toast notification is needed for autoSave
      if (!autoSave) {
        nToast({
          message: status === "done" ? "Failed to submit" : "Failed to save",
          status: "error",
        });
      }
      if (postRes?.data?.success === false && status === "done") {
        //Do not remove this console log as it is being used as a flag for android app.
        console.log("Form Submission Failed");

        // setIsSubmit(false);
      }
    }
  };

  // online status update
  // const updateOnlineStatus = () => {
  //   const online = navigator.onLine;
  //   setIsOnline(online);

  //   if (online) {
  //     nToast({
  //       message: "Back online!",
  //       status: "success",
  //     });
  //   } else {
  //     nToast({
  //       message: "You are offline. Check your connection.",
  //       status: "error",
  //     });
  //   }
  // };

  // Add event listeners for online and offline status changes
  // useEffect(() => {
  //   window.addEventListener("online", updateOnlineStatus);
  //   window.addEventListener("offline", updateOnlineStatus);

  //   return () => {
  //     window.removeEventListener("online", updateOnlineStatus);
  //     window.removeEventListener("offline", updateOnlineStatus);
  //   };
  // }, []);

  useEffect(() => {
    const id = localStorage.getItem("inspectionId");
    const inspectionId = JSON.parse(id);
    setFormData(getElementsState(inspectionId));
  }, [getElementsState]);

  useEffect(() => {
    let authToken = null,
      inspectionId,
      jobId,
      taskId;

    if (isFor !== "web") {
      const token = localStorage.getItem("token");
      const inspectionID = localStorage.getItem("inspectionId");
      const jobID = localStorage.getItem("jobId");
      const taskID = localStorage.getItem("taskId");

      authToken = JSON.parse(token);
      inspectionId = JSON.parse(inspectionID);
      jobId = JSON.parse(jobID);
      taskId = JSON.parse(taskID);
    }

    const setFormDataOnInitialLoad = ({ data, components }) => {
      let newFormData = getElementsState(inspectionId);
      data.forEach((element) => {
        const templateItemId = element.templateItemId;
        newFormData = {
          ...newFormData,
          [templateItemId]: {
            ...element,
          },
        };
      });
      const newFormDataWithMandatoryStatus = getMandatoryStatusInElementState({
        newFormData,
        components,
      });
      setFormData(newFormDataWithMandatoryStatus);
      setLoading(false);
    };

    const formId =
      isFor == "web"
        ? userStorage?.get()?.id === "02c34174-d003-4f9b-a84b-043d24914e2f"
          ? "u5k54m6cgiyqiwwi"
          : "h0xpf4h5l2s5xrv4"
        : id;

    dispatch(
      actions.fetchFormData({
        id: formId,
        ...(isFor !== "web" && { token: authToken }),
        onSuccess: (components) => {
          dispatch(
            actions.fetchSingleTask({
              inspectionId,
              jobId,
              taskId,
              ...(isFor !== "web" && { token: authToken }),
              onSuccess: () => {
                dispatch(
                  actions.fetchReviewData({
                    onSuccess: setFormDataOnInitialLoad,
                    onFailure: () => {
                      setLoading(false);
                    },
                    components,
                    inspectionId,
                    jobId,
                    taskId,
                    ...(isFor !== "web" && { token: authToken }),
                  })
                );
              },
              onFailure: () => {
                setLoading(false);
              },
            })
          );
        },
        onFailure: () => {
          setLoading(false);
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!loading && blurCount !== 0 && blurCount % 5 === 0) {
      handleSubmit("inprogress", page, true);
    }
  }, [blurCount]);

  if (formLoading || loading) {
    return (
      // <Suspense fallback={<Box>Loading...</Box>}>
        <HStack>
          <CircularProgress
            isIndeterminate
            color="#4FD1C5"
            size="26px"
            alignSelf={"center"}
          />
          <Text>Loading...</Text>
        </HStack>
      // </Suspense>
    );
  }

  return (
    // <Suspense fallback={<Text color="red">Loading...</Text>}>
      <Box mt={isFor === "web" ? "75px" : "0px"} w="100%">
        <HeaderGray
          form={form && form[0]}
          pageNumber={page}
          numPages={getDisplayData().displayData.length}
          filledPages={filledPages ? filledPages : page}
        />
        <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
          {smallerScreen ? (
            <></>
          ) : isFor === "web" ? (
            <VStack
              justify="flex-end"
              align="flex-start"
              gap="2px"
              style={{ position: "absolute", left: "72px", bottom: "64px" }}
            >
              <Text
                color="#4A5568"
                fontSize="14px"
                fontWeight="400"
                lineHeight="1.4"
              >
                Powered by
              </Text>
              <Image src={inspeqtLogo} alt="perfeqt logo" />
            </VStack>
          ) : (
            <></>
          )}
          <VStack
            w={isMobileScreen ? "100%" : "740px"}
            style={{
              margin: "0 auto",
              padding: "64px 24px",
            }}
            gap="32px"
            area-label="sections"
            justify="flex-start"
            align="flex-start"
          >
            {displayData &&
              displayData[page] &&
              displayData[page].sections.map((section, index) => {
                return (
                  <Section
                    key={section.id}
                    section={section}
                    data={getComponents(section.id)}
                    formData={formData}
                    formErrorData={formErrorData[page + 1]}
                    handleFormDataChange={handleFormDataChange}
                    displayData={displayData}
                    setPage={setPage}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                    tempError={tempError}
                    setSkippedPages={setSkippedPages}
                    prevPages={prevPages}
                    page={page}
                    index={index}
                    handleBlur={handleBlur}
                    // isOnline={isOnline}
                  />
                );
              })}
            {tempError ? (
              <HStack w="100%" justify="center" align="center">
                <Text
                  aria-label="element label"
                  color="#D93025"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="1.4"
                  textAlign="center"
                >
                  {!isValidFormat
                    ? `Please, Fill in all the mandatory fields.`
                    : isValidFormat}
                </Text>
              </HStack>
            ) : (
              <></>
            )}
            {getDisplayData().displayData.length === 1 ? (
              <Button
                _focus={{ outline: "none", boxShadow: "none" }}
                _active={{ background: "transparent" }}
                isDisabled={
                  Object.values(formData).some((element) => {
                    return element?.parentInfo?.title === "Disclaimer" &&
                      element.value ===
                        "No, I would like to revisit my response"
                      ? true
                      : false;
                  }) ||
                  (selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true))
                }
                label={"Submit"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={(e) => {
                  handleSubmitClick(e, "done");
                }}
              />
            ) : page === 0 ? (
              <Button
                _focus={{ outline: "none", boxShadow: "none" }}
                _active={{ background: "transparent" }}
                isDisabled={
                  selectFilesUploadLoader &&
                  Object.values(selectFilesUploadLoader).includes(true)
                }
                label={"Next"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={(e) => {
                  handleSubmitClick(e, "inprogress");
                }}
              />
            ) : page === getDisplayData().displayData.length - 1 ? (
              <HStack gap="16px" w="100%">
                <Button
                  _focus={{ outline: "none", boxShadow: "none" }}
                  _active={{ background: "transparent" }}
                  isDisabled={
                    selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true)
                  }
                  label={"Back"}
                  colorScheme="login"
                  variant="outline"
                  size="sm"
                  sx={{ width: "100%" }}
                  onClick={(e) => {
                    e.currentTarget.blur(); // Remove focus after click
                    setTempError(false);
                    setIsValidFormat(null);
                    setPage(
                      prevPages.length
                        ? prevPages[prevPages.length - 1] - 1
                        : page - 1
                    );
                    if (prevPages.length) {
                      const newPrevPages = [...prevPages];
                      newPrevPages.pop();
                      setPrevPages(newPrevPages);
                    }
                  }}
                />
                <Button
                  _focus={{ outline: "none", boxShadow: "none" }}
                  _active={{ background: "transparent" }}
                  isDisabled={
                    Object.values(formData).some((element) => {
                      return element?.parentInfo?.title === "Disclaimer" &&
                        element.value ===
                          "No, I would like to revisit my response"
                        ? true
                        : false;
                    }) ||
                    (selectFilesUploadLoader &&
                      Object.values(selectFilesUploadLoader).includes(true))
                  }
                  label={"Submit"}
                  colorScheme="login"
                  size="sm"
                  sx={{ width: "100%" }}
                  onClick={(e) => {
                    handleSubmitClick(e, "done");
                  }}
                />
              </HStack>
            ) : (
              <HStack gap="16px" w="100%">
                <Button
                  _focus={{ outline: "none", boxShadow: "none" }}
                  _active={{ background: "transparent" }}
                  isDisabled={
                    selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true)
                  }
                  label={"Back"}
                  colorScheme="login"
                  variant="outline"
                  size="sm"
                  sx={{ width: "100%" }}
                  onClick={(e) => {
                    e.currentTarget.blur(); // Remove focus after click
                    setTempError(false);
                    setIsValidFormat(null);
                    setPage(
                      prevPages.length
                        ? prevPages[prevPages.length - 1] - 1
                        : page - 1
                    );
                    if (prevPages.length) {
                      const newPrevPages = [...prevPages];
                      newPrevPages.pop();
                      setPrevPages(newPrevPages);
                      setNavIndex(null);
                    }
                  }}
                />
                <Button
                  _focus={{ outline: "none", boxShadow: "none" }}
                  _active={{ background: "transparent" }}
                  isDisabled={
                    selectFilesUploadLoader &&
                    Object.values(selectFilesUploadLoader).includes(true)
                  }
                  label={"Next"}
                  colorScheme="login"
                  size="sm"
                  sx={{ width: "100%" }}
                  onClick={(e) => {
                    handleSubmitClick(e, "inprogress");
                  }}
                />
              </HStack>
            )}
          </VStack>
        </Box>
      </Box>
    // </Suspense>
  );
}
