import React, { useEffect, useState } from "react";
import { Stack, Box, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

export default function MultiSelect({
  data,
  handleFormDataChange,
  formData,
  repeatable,
  index,
  handleBlur,
}) {
  if (repeatable?.isRepeatable) {
    const repeatIndex = index;
    const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);

    const values = formData[data.element.id].attachments[repeatIndex]
      ? JSON.parse(formData[data.element.id].attachments[repeatIndex])
      : [];

    const handleChange = (val) => {
      handleFormDataChange(
        JSON.stringify(val),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    };

    useEffect(() => {
      handleFormDataChange(
        JSON.stringify(values),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    }, []);

    return (
      <Stack
        onBlur={() => {
          if (!isCheckboxFocused) {
            handleBlur();
          }
        }}
        onFocus={() => setIsCheckboxFocused(true)}
        onMouseLeave={() => setIsCheckboxFocused(false)}
      >
        {formData[data.element.id] ? (
          <CheckboxGroup
            name={`${data.element.id}${repeatIndex}`}
            value={values}
            onChange={(val) => {
              handleChange(val);
            }}
          >
            {data &&
            data.element &&
            data.element.properties &&
            data.element.properties.options ? (
              data.element.properties.options.map((option) => {
                const capitalizedOption = capitalizeStringUpdated(option.label);
                return (
                  <Box
                    style={{
                      border: "1px solid #EDF2F7",
                      borderRadius: "8px",
                      padding: "12px",
                    }}
                    key={option.value}
                  >
                    <Checkbox
                      size="md"
                      colorScheme="login"
                      value={option.value}
                      onMouseEnter={() => setIsCheckboxFocused(true)} // Set true when mouse enters checkbox
                      onMouseLeave={() => setIsCheckboxFocused(false)}
                    >
                      {capitalizedOption}
                    </Checkbox>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </CheckboxGroup>
        ) : (
          <></>
        )}
      </Stack>
    );
  }
  const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);
  return (
    <Stack
      onBlur={() => {
        if (!isCheckboxFocused) {
          handleBlur();
        }
      }}
      onFocus={() => setIsCheckboxFocused(true)}
      onMouseLeave={() => setIsCheckboxFocused(false)}
    >
      {formData[data.element.id] ? (
        <CheckboxGroup
          name={data.element.id}
          value={formData[data.element.id].attachments}
          onChange={(val) => {
            handleFormDataChange(val, "", data.element.id, "NA");
          }}
        >
          {data &&
          data.element &&
          data.element.properties &&
          data.element.properties.options ? (
            data.element.properties.options.map((option) => {
              const capitalizedOption = capitalizeStringUpdated(option.label);
              return (
                <Box
                  style={{
                    border: "1px solid #EDF2F7",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                  key={option.value}
                >
                  <Checkbox
                    size="md"
                    colorScheme="login"
                    value={option.value}
                    onMouseEnter={() => setIsCheckboxFocused(true)} // Set true when mouse enters checkbox
                    onMouseLeave={() => setIsCheckboxFocused(false)} // Set false when mouse leaves checkbox
                  >
                    {capitalizedOption}
                  </Checkbox>
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </CheckboxGroup>
      ) : (
        <></>
      )}
    </Stack>
  );
}
