import React, { useCallback } from "react";
import { Box } from "@chakra-ui/react";

import ShortAnswer from "../../../components/shortAnswer";
import LongAnswer from "../../../components/longAnswer";
import SingleSelect from "../../../components/singleSelect";
import MultiSelect from "../../../components/multiSelect";
import CheckBox from "../../../components/checkbox";
import Dropdown from "../../../components/dropdown";
import FileUpload from "../../../components/fileUpload";
import FileUploads from "../../../components/fileUploads";
import MediaUpload from "../../../components/mediaUpload";
import Address from "../../../components/address";
import MultiTextFields from "../../../components/multiTextFields";
import Slider from "../../../components/slider";
import Email from "../../../components/email";
import Number from "../../../components/number";
import DateTimePicker from "../../../components/datetimepicker";
import CheckboxWithSearch from "views/Workflow/components/checkboxWithSearch";
import DropdownWithSearch from "views/Workflow/components/dropdownWithSearch";
import Mobile from "../../../components/mobile";

// Lazy load all components
// const ShortAnswer = React.lazy(() => import("../../../components/shortAnswer"));
// const LongAnswer = React.lazy(() => import("../../../components/longAnswer"));
// const SingleSelect = React.lazy(() =>
//   import("../../../components/singleSelect")
// );
// const MultiSelect = React.lazy(() => import("../../../components/multiSelect"));
// const CheckBox = React.lazy(() => import("../../../components/checkbox"));
// const Dropdown = React.lazy(() => import("../../../components/dropdown"));
// const FileUpload = React.lazy(() => import("../../../components/fileUpload"));
// const FileUploads = React.lazy(() => import("../../../components/fileUploads"));
// const MediaUpload = React.lazy(() => import("../../../components/mediaUpload"));
// const Address = React.lazy(() => import("../../../components/address"));
// const MultiTextFields = React.lazy(() =>
//   import("../../../components/multiTextFields")
// );
// const Slider = React.lazy(() => import("../../../components/slider"));
// const Email = React.lazy(() => import("../../../components/email"));
// const Number = React.lazy(() => import("../../../components/number"));
// const DateTimePicker = React.lazy(() =>
//   import("../../../components/datetimepicker")
// );
// const CheckboxWithSearch = React.lazy(() =>
//   import("views/Workflow/components/checkboxWithSearch")
// );
// const DropdownWithSearch = React.lazy(() =>
//   import("views/Workflow/components/dropdownWithSearch")
// );
// const Mobile = React.lazy(() => import("../../../components/mobile"));

export default function Element({
  elementType,
  formData,
  handleFormDataChange,
  data,
  setPage,
  displayData,
  navIndex,
  setNavIndex,
  setSkippedPages,
  prevPages,
  page,
  repeatable,
  index,
  handleBlur,
  // isOnline
}) {
  const getElement = useCallback(() => {
    const elementMap = {
      textInput: (
        <ShortAnswer
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      textarea: (
        <LongAnswer
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      radio: (
        <SingleSelect
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          setPage={setPage}
          displayData={displayData}
          navIndex={navIndex}
          setNavIndex={setNavIndex}
          setSkippedPages={setSkippedPages}
          prevPages={prevPages}
          page={page}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      checkbox: (
        <MultiSelect
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      checkboxSearch: (
        <CheckboxWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      switch: (
        <CheckBox
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      dropdown: (
        <Dropdown
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      dropdownWithSearch: (
        <DropdownWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      fileUpload: (
        <FileUploads
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
          // isOnline={isOnline}
        />
      ),
      singleFileUpload: (
        <FileUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      multiFileUpload: (
        <FileUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      mediaUpload: (
        <MediaUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      address: (
        <Address
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      multiTextFields: (
        <MultiTextFields
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleBlur={handleBlur}
        />
      ),
      slider: <Slider />,
      email: (
        <Email
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      numberInput: (
        <Number
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      datepicker: (
        <DateTimePicker
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
      mobile: (
        <Mobile
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
          handleBlur={handleBlur}
        />
      ),
    };
    return elementMap[elementType];
  }, [
    elementType,
    formData,
    handleFormDataChange,
    data,
    setPage,
    displayData,
    navIndex,
    setNavIndex,
  ]);
  return (
    <Box w="100%" style={{ margin: "16px auto" }}>
      {getElement()}
    </Box>
  );
}
