import React, { useState, useEffect } from "react";
import {
  HStack,
  Input,
  IconButton,
  Select,
  VStack,
  Text,
} from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { AddIcon, WarningIcon } from "@chakra-ui/icons";
import { IN } from "country-flag-icons/react/3x2";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { validateMobileNumber } from "utils/helper";

export default function Mobile({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
  handleBlur,
}) {
  const countryCodes = [
    { label: "India +91", value: "+91" },
    { label: "UAE +971", value: "+971" },
  ];

  if (data.element.repeatable?.isRepeatable || repeatable?.isRepeatable) {
    let minEntries, maxEntries, unlimitedEntries;
    if (data.element.repeatable?.isRepeatable) {
      minEntries = data.element.repeatable?.minEntries;
      maxEntries = data.element.repeatable?.maxEntries;
      unlimitedEntries = data.element.repeatable?.unlimitedEntries;
    }
    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
          selectedCode: "+91",
          isPhoneNoValid: { isValid: true, message: "" },
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
        selectedCode: "+91",
        isPhoneNoValid: { isValid: true, message: "" },
      });
    }
    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => {
              const valArr = value.split(",");
              const val = valArr.length > 1 ? valArr[1] : valArr[0];
              const code = valArr.length > 1 ? valArr[0] : "+91";
              return {
                order: index + 1,
                value: val,
                selectedCode: code,
                isPhoneNoValid: { isValid: true, message: "" },
              };
            }),
            // {
            //   order:
            //     formData[data.element.id]?.attachments[
            //       formData[data.element.id]?.attachments?.length - 1
            //     ]?.order + 1,
            //   value: "",
            // },
          ]
        : initialValues
    );

    const handleAddMore = () => {
      if (
        !values[values.length - 1].value ||
        (values.length >= maxEntries && !unlimitedEntries)
      )
        return;

      handleFormDataChange(
        { target: { name: data.element.id, value: "" } },
        "repeatable",
        values[values.length - 1]?.order + 1
      );
      setValues((prevValues) => [
        ...prevValues,
        {
          order: prevValues[prevValues.length - 1].order + 1,
          value: "",
          selectedCode: "+91",
          isPhoneNoValid: { isValid: true, message: "" },
        },
      ]);
    };

    const handleDelete = (order) => {
      if (values.length < 2 || values.length <= minEntries) return;
      const newVals = values.filter((value) => value.order !== order);
      setValues(newVals);
      handleFormDataChange(data.element.id, "repeatable-delete", order - 1);
    };

    const elementData = formData[data.element.id]?.attachments;
    useEffect(() => {
      if (elementData?.length) {
        setValues(
          elementData.map((value, index) => {
            const valArr = value.split(",");
            const val = valArr.length > 1 ? valArr[1] : valArr[0];
            const code = valArr.length > 1 ? valArr[0] : "+91";
            return {
              order: index + 1,
              value: val,
              selectedCode: code,
              isPhoneNoValid: validateMobileNumber(val, code),
            };
          })
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            {
              target: {
                name: data.element.id,
                value: `${value.selectedCode},${value.value}`,
              },
            },
            "repeatable",
            value.order
          );
        });
      }
    }, [elementData]);

    useEffect(()=>{
      const val = formData[data.element.id]?.attachments[index];
      if(index && repeatable?.isRepeatable && !val){
        handleFormDataChange(
          { target: { name: data.element.id, value:`+91,` } },
          "repeatable",
          index + 1
        );
      }
    },[])

    if (repeatable?.isRepeatable) {
      const value = values[index] || { value: "", selectedCode: "+91" };
      return (
        <VStack>
          <HStack w="100%" mb="8px">
            <HStack
              w="100%"
              h="48px"
              style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
            >
              <HStack
                w="150px"
                h="48px"
                justify="center"
                align="center"
                pl="10px"
              >
                <Select
                  variant="unstyled"
                  value={value?.selectedCode}
                  onChange={(e) => {
                    value.selectedCode = e.target.value;
                    const combinedValue = `${e.target.value},${value?.value}`;
                    const ee = {
                      target: { name: data.element.id, value: combinedValue },
                    };
                    handleFormDataChange(ee, "repeatable", index + 1);
                  }}
                >
                  {countryCodes?.map((code) => (
                    <option key={code.value} value={code.value}>
                      {code.label}
                    </option>
                  ))}
                </Select>
              </HStack>
              <Input
                type="text"
                inputMode="numeric"
                variant="unstyled"
                placeholder={data.element.placeholder || "Mobile"}
                required={data.element.mandatory}
                color="#2D3748"
                _placeholder={{ color: "#A0AEC0" }}
                name={data.element.id}
                value={value?.value}
                onChange={(e) => {
                  value.value = e.target.value;
                  const combinedValue = `${value?.selectedCode},${e.target.value}`;
                  const ee = {
                    target: { name: data.element.id, value: combinedValue },
                  };
                  handleFormDataChange(ee, "repeatable", index + 1);
                }}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                onBlur={handleBlur}
              />
            </HStack>
          </HStack>
          {value?.isPhoneNoValid?.isValid === false ? (
            <ErrorMessage message={value?.isPhoneNoValid.message} />
          ) : null}
        </VStack>
      );
    }

    return (
      <>
        {values &&
          values.map((value) => (
            <VStack key={value.order}>
              <HStack w="100%" mb="8px">
                <HStack
                  w="100%"
                  h="48px"
                  style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
                >
                  <HStack
                    w="150px"
                    h="48px"
                    justify="center"
                    align="center"
                    pl="10px"
                  >
                    <Select
                      variant="unstyled"
                      value={value?.selectedCode}
                      onChange={(e) => {
                        value.selectedCode = e.target.value;
                        const combinedValue = `${e.target.value},${value?.value}`;
                        const ee = {
                          target: {
                            name: data.element.id,
                            value: combinedValue,
                          },
                        };
                        handleFormDataChange(ee, "repeatable", value?.order);
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option key={code.value} value={code.value}>
                          {code.label}
                        </option>
                      ))}
                    </Select>
                  </HStack>
                  <Input
                    type="text"
                    variant="unstyled"
                    inputMode="numeric"
                    placeholder={data.element.placeholder || "Mobile"}
                    required={data.element.mandatory}
                    color="#2D3748"
                    _placeholder={{ color: "#A0AEC0" }}
                    name={data.element.id}
                    value={value?.value}
                    onChange={(e) => {
                      value.value = e.target.value;
                      const combinedValue = `${value?.selectedCode},${e.target.value}`;
                      const ee = {
                        target: { name: data.element.id, value: combinedValue },
                      };
                      handleFormDataChange(ee, "repeatable", value?.order);
                    }}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    onBlur={handleBlur}
                  />
                </HStack>
                <IconButton
                  variant="ghost"
                  borderRadius="8px"
                  isDisabled={values.length < 2 || values.length <= minEntries}
                  sx={{
                    ".icon": {
                      color: "red.500",
                    },
                  }}
                  icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                  onClick={() => {
                    handleDelete(value?.order);
                  }}
                />
              </HStack>
              {value?.isPhoneNoValid?.isValid === false ? (
                <ErrorMessage message={value?.isPhoneNoValid.message} />
              ) : null}
            </VStack>
          ))}
        <Button
          size="sm"
          borderRadius="8px"
          onClick={handleAddMore}
          leftIcon={<AddIcon />}
          label="Add More"
          isDisabled={
            !values[values.length - 1].value ||
            (values.length >= maxEntries && !unlimitedEntries)
          }
        />
      </>
    );
  }

  const [selectedCode, setSelectedCode] = useState("+91");
  const [mobileNo, setMobileNo] = useState("");
  const [isPhoneNoValid, setIsPhoneNoValid] = useState({
    isValid: true,
    message: "",
  });

  useEffect(() => {
    const combinedValue = `${selectedCode},${mobileNo}`;
    const e = {
      target: { name: data.element.id, value: combinedValue },
    };
    handleFormDataChange(e);
    if (selectedCode || mobileNo) {
      setIsPhoneNoValid(validateMobileNumber(mobileNo, selectedCode));
    }
  }, [selectedCode, mobileNo]);
  useEffect(() => {
    if (formData[data.element.id]?.value) {
      const mobileArr = formData[data.element.id]?.value?.split(",") || [];
      const number = mobileArr.length > 1 ? mobileArr[1] : mobileArr[0];
      const code = mobileArr.length > 1 ? mobileArr[0] : "+91";
      setSelectedCode(code);
      setMobileNo(number);
    }
  }, []);
  return (
    <VStack>
      <HStack
        w="100%"
        h="48px"
        style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
      >
        <HStack w="150px" h="48px" justify="center" align="center" pl="10px">
          <Select
            variant="unstyled"
            value={selectedCode}
            onChange={(e) => {
              setSelectedCode(e.target.value);
            }}
          >
            {countryCodes?.map((code) => (
              <option key={code.value} value={code.value}>
                {code.label}
              </option>
            ))}
          </Select>
        </HStack>
        <Input
          type="text"
          inputMode="numeric"
          variant="unstyled"
          placeholder={data.element.placeholder || "Mobile"}
          required={data.element.mandatory}
          color="#2D3748"
          _placeholder={{ color: "#A0AEC0" }}
          name={data.element.id}
          value={formData[data.element.id]?.value?.split(",")[1]}
          onChange={(e) => {
            setIsPhoneNoValid(
              validateMobileNumber(e.target.value, selectedCode)
            );
            setMobileNo(e.target.value.trim());
          }}
          onInput={(e) =>
            (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
          }
          onBlur={handleBlur}
        />
      </HStack>
      {isPhoneNoValid?.isValid === false ? (
        <ErrorMessage message={isPhoneNoValid.message} />
      ) : null}
    </VStack>
  );
}

function ErrorMessage({ message }) {
  return (
    <HStack w="100%" justify="flex-start" align="center" mb="20px">
      <WarningIcon color="#D93025" />
      <Text
        aria-label="element label"
        color="#D93025"
        fontSize="14px"
        fontWeight="400"
        lineHeight="1.4"
        textAlign="center"
      >
        {message}
      </Text>
    </HStack>
  );
}
