import React, { useEffect, useState } from "react";
import { HStack, Input, IconButton, VStack, Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { AddIcon, WarningIcon } from "@chakra-ui/icons";
import {
  MdOutlineAlternateEmail,
  MdOutlineDeleteOutline,
} from "react-icons/md";
import { validateEmail } from "utils/helper";
import _ from "lodash";

export default function Email({
  formData,
  handleFormDataChange,
  data,
  repeatable,
  index,
  handleBlur,
}) {
  if (data.element.repeatable?.isRepeatable || repeatable?.isRepeatable) {
    const domainArr =
      formData[data.element.id]?.itemInfo?.properties?.emailDomains;

    let minEntries, maxEntries, unlimitedEntries;
    if(data.element.repeatable?.isRepeatable){
      minEntries = data.element.repeatable?.minEntries;
      maxEntries = data.element.repeatable?.maxEntries;
      unlimitedEntries = data.element.repeatable?.unlimitedEntries;
    }

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
          isEmailValidated: { isValid: true, message: "" },
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
        isEmailValidated: { isValid: true, message: "" },
      });
    }

    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
              isEmailValidated: { isValid: true, message: "" },
            })),
            //This part will be removed after the testing is verified.
            // {
            //   order:
            //     formData[data.element.id]?.attachments[
            //       formData[data.element.id]?.attachments?.length - 1
            //     ]?.order + 1,
            //   value: "",
            // },
          ]
        : initialValues
    );
    const handleAddMore = () => {
      if (
        !values[values.length - 1].value ||
        (values.length >= maxEntries && !unlimitedEntries)
      )
        return;

      handleFormDataChange(
        { target: { name: data.element.id, value: "" } },
        "repeatable",
        values[values.length - 1]?.order + 1
      );
      setValues((prevValues) => [
        ...prevValues,
        {
          order: prevValues[prevValues.length - 1].order + 1,
          value: "",
          isEmailValidated: { isValid: true, message: "" },
        },
      ]);
    };

    const handleDelete = (order) => {
      if (values.length < 2 || values.length <= minEntries) return;
      const newVals = values.filter((value) => value.order !== order);

      setValues(newVals);
      handleFormDataChange(data.element.id, "repeatable-delete", order - 1);
    };

    const elementData = formData[data.element.id]?.attachments;
    useEffect(() => {
      if (elementData?.length) {
        setValues(
          elementData.map((value, index) => ({
            order: index + 1,
            value,
            isEmailValidated: validateEmail(value, domainArr),
          }))
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            { target: { name: data.element.id, value: value.value } },
            "repeatable",
            value.order
          );
        });
      }
    }, [elementData]);

    useEffect(() => {
      const val = formData[data.element.id]?.attachments[index];
      if (index && repeatable?.isRepeatable && !val) {
        handleFormDataChange(
          { target: { name: data.element.id, value: "" } },
          "repeatable",
          index + 1
        );
      }
    }, []);  

    if (repeatable?.isRepeatable) {
      const value = values[index];
      return (
        <VStack>
          <HStack w="100%" mb="8px">
            <HStack
              w="100%"
              h="48px"
              style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
            >
              <HStack w="48px" h="48px" justify="center" align="center">
                <MdOutlineAlternateEmail
                  size={18}
                  style={{ fill: "#718096" }}
                />
              </HStack>
              <Input
                type="email"
                variant="unstyled"
                placeholder={data.element.placeholder || "Email"}
                required={data.element.mandatory}
                color="#2D3748"
                _placeholder={{ color: "#A0AEC0" }}
                name={data.element.id}
                value={elementData[index]}
                onChange={(e) => {
                  handleFormDataChange(e, "repeatable", index + 1);
                }}
                onBlur={handleBlur}
              />
            </HStack>
          </HStack>
          {value?.isEmailValidated?.isValid === false ? (
            <ErrorMessage message={value?.isEmailValidated?.message} />
          ) : null}
        </VStack>
      );
    }

    return (
      <>
        {values &&
          values.map((value, i) => {
            return (
              <VStack>
                <HStack w="100%" mb="8px" key={value.order}>
                  <HStack
                    w="100%"
                    h="48px"
                    style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
                  >
                    <HStack w="48px" h="48px" justify="center" align="center">
                      <MdOutlineAlternateEmail
                        size={18}
                        style={{ fill: "#718096" }}
                      />
                    </HStack>
                    <Input
                      type="email"
                      variant="unstyled"
                      placeholder={data.element.placeholder || "Email"}
                      required={data.element.mandatory}
                      color="#2D3748"
                      _placeholder={{ color: "#A0AEC0" }}
                      name={data.element.id}
                      value={elementData[i]}
                      onChange={(e) => {
                        handleFormDataChange(e, "repeatable", value.order);
                      }}
                onBlur={handleBlur}

                    />
                  </HStack>
                  <IconButton
                    variant="ghost"
                    borderRadius="8px"
                    isDisabled={
                      values.length < 2 || values.length <= minEntries
                    }
                    sx={{
                      ".icon": {
                        color: "red.500",
                      },
                    }}
                    icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                    onClick={() => {
                      handleDelete(value.order);
                    }}
                  />
                </HStack>
                {value?.isEmailValidated?.isValid === false ? (
                  <ErrorMessage message={value?.isEmailValidated?.message} />
                ) : null}
              </VStack>
            );
          })}
        <Button
          size="sm"
          borderRadius="8px"
          onClick={handleAddMore}
          leftIcon={<AddIcon />}
          label="Add More"
          isDisabled={
            !values[values.length - 1].value ||
            (values.length >= maxEntries && !unlimitedEntries)
          }
        />
      </>
    );
  }
  const [isEmailValidated, setIsEmailValidated] = useState(true);
  const domainArr =
    formData[data.element.id]?.itemInfo?.properties?.emailDomains;

  const [text, setText] = useState("");

  const elementData = formData[data.element.id]?.value;
  useEffect(() => {
    if (elementData && text !== elementData) {
      setText(elementData);
    }
  }, [elementData]);

  useEffect(() => {
    if (text !== elementData) {
      handleFormDataChange({ target: { name: data.element.id, value: text } });
    }
  }, [text]);
  return (
    <VStack>
      <HStack
        w="100%"
        h="48px"
        style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
      >
        <HStack w="48px" h="48px" justify="center" align="center">
          <MdOutlineAlternateEmail size={18} style={{ fill: "#718096" }} />
        </HStack>
        <Input
          type="email"
          variant="unstyled"
          placeholder={data.element.placeholder || "Email"}
          required={data.element.mandatory}
          color="#2D3748"
          _placeholder={{ color: "#A0AEC0" }}
          name={data.element.id}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            setIsEmailValidated(validateEmail(e.target.value, domainArr));
          }}
          onBlur={handleBlur}

        />
      </HStack>
      {isEmailValidated.isValid === false ? (
        <ErrorMessage message={isEmailValidated.message} />
      ) : null}
    </VStack>
  );
}

function ErrorMessage({ message }) {
  return (
    <HStack w="100%" justify="flex-start" align="center" mb="20px">
      <WarningIcon color="#D93025" />
      <Text
        aria-label="element label"
        color="#D93025"
        fontSize="14px"
        fontWeight="400"
        lineHeight="1.4"
        textAlign="center"
      >
        {message}
      </Text>
    </HStack>
  );
}
